import React from "react"
import { FaCode, FaSketch, FaAndroid } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />  ,
    title: "Learn",
    text: `Learn the basic characteristics of different diseases and conditions.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "Quizzes",
    text: `Test your knowledge through quick trick questions, and thus improve it.`,
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: "Private cooperation",
    text: `Contact us and send us cases privately,to get a professional response from us.`,
  },
]
