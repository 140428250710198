import React from "react"
import Title from "./Title"

import Layout from "../components/Layout"
const NewsLetter = () => {
  return (
      
      <section className="section bg-grey">
          <br></br>        <br></br>
              <Title title= "Get email when a new case is added, please subscribe."></Title>
        <div className="page-center">
          <form
            className="contact-form"
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="text"
              name="name"
              placeholder="Your name"
              className="form-control "
            />
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Your email"
            />
            <button type="submit" className="btn form-control submit-btn">
              subscribe
            </button>
           
          </form>
        </div>
        <br></br>          
      <br></br>
        <br></br>


      </section>
  )
}

export default NewsLetter
